
export default {
    name: 'Tabs',
    model: {
        prop: 'selectedIndex',
        event: 'input'
    },
    props: {
        theme: {
            type: String,
            default: 'button-primary',
            validator: val => ['button-primary', 'button-brand', 'button-gray', 'button-white', 'underline', 'sideline', 'underline-filled'].includes(val)
        },
        size: {
            type: String,
            default: 'sm',
            validator: val => ['sm', 'md'].includes(val)
        },
        isVertical: {
            type: Boolean,
            default: false
        },
        fill: {
            type: Boolean,
            default: false
        },
        selectedIndex: {
            type: [Number, String],
            default: 0,
            required: false
        }
    },
    data () {
        return {
            tabs: []
        }
    },
    computed: {
        classes () {
            const classes = []

            if (this.isVertical) {
                classes.push('flex-col')
            }

            if (this.fill) {
                classes.push('[&>*]:flex-1')
            }

            if (['underline', 'underline-filled'].includes(this.theme)) {
                classes.push('border-b border-b-gray-200')
            }

            if (['button-primary', 'button-gray', 'button-white', 'button-brand'].includes(this.theme)) {
                classes.push('gap-1')
            }

            if (['underline', 'sideline', 'underline-filled'].includes(this.theme)) {
                classes.push('gap-3')
            }

            if (this.theme === 'button-white') {
                classes.push('p-1 rounded-xl bg-gray-50 border border-gray-200')
            }

            if (this.theme === 'button-brand') {
                classes.push('p-1.5 rounded-full bg-slate-100')
            }

            return classes.join(' ')
        }
    },
    watch: {
        selectedIndex: {
            handler (val) {
                this.setSelected(val)
            },
            immediate: true
        }
    },
    created () {
        this.tabs = this.$children
    },
    mounted () {
        const selectedTab = this.tabs.find(t => t.isSelected)
        if (!selectedTab) {
            this.setSelected(this.selectedIndex)
        }
    },
    methods: {
        setSelected (selectedIndex) {
            this.tabs.forEach((t, index) => {
                t.isSelected = (index === selectedIndex)
            })
        },
        checkSelected (e) {
            const el = 'isTab' in e.target.dataset ? e.target : e.target.closest('[data-is-tab]')
            if (!el) {
                return;
            }
            const selectedIndex = Array.from(this.$el.children).findIndex(ch => ch === el)
            const selectedTab = this.tabs[selectedIndex]
            if (!selectedTab.selectable) {
                e.preventDefault()
                return
            }
            this.$emit('input', selectedIndex)
        }
    }
}
