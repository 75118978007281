
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    name: 'InputField',
    components: {
        Icon
    },
    inheritAttrs: false,
    props: {
        label: {
            type: [Boolean, String],
            default: null
        },
        hint: {
            type: [Boolean, String],
            default: null
        },
        help: {
            type: [Boolean, String],
            default: null
        },
        error: {
            type: [Boolean, String],
            default: null
        },
        disabled: {
            type: Boolean,
            default: null
        },
        size: {
            type: String,
            default: 'sm',
            validator: val => ['sm', 'md'].includes(val)
        },
        rounded: {
            type: String,
            default: () => 'lg',
            validator: val => ['lg', 'full'].includes(val)
        }
    },
    methods: {
        // For v-model
        exceptInputListeners (listeners) {
            return Object.keys(listeners)
                .filter(key => key !== 'input')
                .reduce((obj, key) => {
                    obj[key] = listeners[key]
                    return obj
                }, {})
        }
    }
}
