
export default {
    name: 'Tab',
    props: {
        theme: {
            type: String,
            default: null,
            validator: val => ['button-primary', 'button-brand', 'button-gray', 'underline', 'sideline', 'underline-filled', 'button-white'].includes(val)
        },
        size: {
            type: String,
            default: 'sm',
            validator: val => ['sm', 'md'].includes(val)
        },
        selectable: {
            type: Boolean,
            default: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        componentIs: {
            type: String,
            default: 'button'
        }
    },
    data () {
        return {
            isSelected: this.selected
        }
    },
    computed: {
        // c = current
        cTheme () {
            return this.theme ?? this.$parent.theme
        },
        cSize () {
            return this.$parent.size ?? this.tabSize
        },
        classes () {
            const classes = []

            // Font Size
            classes.push('font-semibold')
            classes.push({
                sm: 'text-sm',
                md: 'text-md'
            }[this.cSize])

            // Paddings
            classes.push('px-3 py-2')

            // Border Radius
            if (!['underline', 'sideline', 'underline-filled', 'button-brand'].includes(this.cTheme)) {
                classes.push('rounded-lg')
            }
            if (this.cTheme === 'button-brand') {
                classes.push('rounded-full')
            }
            if (this.disabled) {
                classes.push('text-white bg-gray-400')
                return classes.join(' ')
            }
            // Colors
            if (this.isSelected) {
                classes.push({
                    'button-primary': 'text-brand-500 bg-brand-50 focus:ring-4 focus:ring-brand-100',
                    'button-brand': 'text-white bg-brand-500 focus:ring-4 focus:ring-brand-100',
                    'button-gray': 'text-gray-700 bg-gray-50 focus:ring-4 focus:ring-gray-100',
                    'button-white': 'text-gray-700 bg-white shadow focus:ring-4 focus:ring-gray-100',
                    'underline': 'text-brand-500 border-b-2 border-b-brand-600',
                    'sideline': 'text-brand-500 border-e-2 border-e-brand-600',
                    'underline-filled': 'text-brand-500 bg-brand-50 border-b-2 border-b-brand-600'
                }[this.cTheme])
            } else {
                classes.push({
                    'button-primary': 'text-gray-500 hover:text-brand-500 hover:bg-brand-50 focus:ring-4 focus:ring-brand-100 focus:bg-white',
                    'button-brand': 'text-gray-600 hover:text-brand-500 hover:bg-brand-50 focus:ring-4 focus:ring-brand-100 focus:bg-white',
                    'button-gray': 'text-gray-500 hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-gray-100 focus:bg-white',
                    'button-white': 'text-gray-500 hover:text-gray-700 hover:bg-white hover:shadow focus:ring-4 focus:ring-gray-100 focus:bg-white',
                    'underline': 'text-gray-500 hover:text-brand-500 hover:border-b-2 hover:border-b-brand-600 focus:border-0 focus:text-gray-500',
                    'sideline': 'text-gray-500 hover:text-brand-500 hover:border-e-2 hover:border-e-brand-600 focus:border-0 focus:text-gray-500',
                    'underline-filled': 'text-gray-500 hover:text-brand-500 hover:border-b-2 hover:border-b-brand-600 focus:border-0 focus:text-gray-500'
                }[this.cTheme])
            }

            return classes.join(' ')
        }
    }
}
