
import Tabs from '@/components/UntitledUI/Tabs/Tabs.vue'
import Tab from '@/components/UntitledUI/Tabs/Tab.vue'
import Login from '@/components/Onboarding/Login.vue'
import Sms2FA from '@/components/common/TwoFactorAuth/Sms2FA.vue'
import ChangeLogsPreview from '@/components/Onboarding/ChangeLogsPreview.vue'
import * as Sentry from '@sentry/vue'

export default {
    layout: 'onboarding',
    auth: 'guest',
    components: {
        ChangeLogsPreview,
        Tabs,
        Tab,
        Login,
        Sms2FA
    },
    async asyncData ({ $axios, store }) {
        try {
            let changelogs = store.getters['onboarding/getChangelogsPreview']
            if (!changelogs) {
                changelogs = await $axios.$get('/v1/changelogs/preview')
                store.commit('onboarding/setChangelogsPreview', changelogs)
            }
            return { changelogs }
        } catch (e) {
            Sentry.captureException(e)
            return {}
        }
    },
    data () {
        return {
            form: new this.$vForm({
                email: undefined,
                password: undefined,
                remember: false
            }),
            hidden: {
                password: true
            },
            tab: 0,
            waitTime: 0,
            phone: null,
            tfa: null,
            changelogs: []
        }
    },
    watch: {
        tab () {
            this.$router.push(this.localeLocation({ path: '/Register', query: this.$route.query }))
        }
    },
    methods: {
        async handleLogin (data) {
            if (data?.id) {
                const user = data
                await this.$auth.setStrategy('cookie').then(() => {
                    this.$auth.getStrategy().updateTokens()
                    this.$auth.setUser(user)
                    window.hj('identify', this.$auth.user?.id || null, {})
                    Sentry.setUser(
                        this.$auth.user
                            ? {
                                id: this.$auth.user.id,
                                email: this.$auth.user.email
                            }
                            : null)
                })
                this.$gtm.push({
                    event: 'login',
                    method: 'email',
                    user_id: data.id + ''
                })
            } else {
                this.phone = data.phone
                this.tfa = data['2fa']
                this.waitTime = data.wait ?? 0
            }
        }
    },
    head () {
        return {
            title: this.$t('تسجيل الدخول')
        }
    }
}
