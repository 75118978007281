
import Checkbox from '@/components/UntitledUI/Checkbox.vue'
import InputField from '@/components/UntitledUI/InputField.vue'
import Button from '@/components/UntitledUI/Button.vue'
import Icon from '@/components/UntitledUI/Icon.vue'
import * as Sentry from '@sentry/vue'

export default {
    name: 'Login',
    components: {
        InputField,
        Checkbox,
        Button,
        Icon
    },
    data () {
        return {
            alertData: null,
            form: new this.$vForm({
                email: undefined,
                password: undefined,
                remember: false
            }),
            hidden: {
                password: true
            }
        }
    },
    computed: {
        btnDisabled () {
            return this.form.busy || !this.form.email || !this.form.password
        }
    },
    methods: {
        async login () {
            if (this.btnDisabled) { return }
            this.alertData = null
            this.form.busy = true
            await this.$axios.$get('/v1/sanctum/csrf-cookie')
            await this.form.post('/v1/login')
                .then((res) => {
                    this.$emit('finished', res.data)
                    // this.userPhone = res.data.phone
                    // this.twoFactorSession = res.data['2fa']
                })
                .catch((e) => {
                    if (e.response?.status && 499 < e.response?.status && e.response?.status < 400) {
                        this.alert({ type: 'danger', body: this.$t('لقد حصل خطأ ما'), time: 3000 })
                        throw e
                    }
                    if (this.form.errors.any()) {
                        this.alert({ type: 'danger', body: this.form.errors.flatten()[0], time: 3000 })
                    }
                })
        },
        success (res) {
            const user = res.data
            this.$auth.setStrategy('cookie').then(() => {
                this.$auth.getStrategy().updateTokens()
                this.$auth.setUser(user)
                window.hj('identify', this.$auth.user?.id || null, {})
                Sentry.setUser(
                    this.$auth.user
                        ? {
                            id: this.$auth.user.id,
                            email: this.$auth.user.email
                        }
                        : null)
            })
        },
        reset () {
            this.form.reset()
            this.form.clear()
            this.form.successful = false
            this.alertData = null
        },
        alert ({ type, head, body, time }) {
            this.alertData = { type, head, body, time }
            if (typeof time === 'number') {
                clearTimeout(this.alertTimeout)
                this.alertTimeout = setTimeout(() => { this.alertData = null }, time)
            }
        }
    },
    emits: ['finished']
}
