
import Splide from '@splidejs/splide'

export default {
    name: 'ChangeLogsPreview',
    props: {
        changelogs: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            splide: null
        }
    },
    mounted () {
        this.destroy()
        this.init()
    },
    destroyed () {
        this.destroy()
    },
    beforeDestroy () {
        this.$refs.splide?.classList.add('opacity-0')
    },
    methods: {
        init () {
            this.$refs.splide?.classList.remove('opacity-0')
            this.splide = new Splide(this.$refs.splide, {
                arrows: false,
                focus: 0,
                pagination: true,
                type: 'loop',
                direction: this.$helpers.getDir(),
                gap: '1rem',
                classes: {}
            }).mount()
        },
        destroy () {
            if (this.splide) {
                this.splide.destroy()
            }
        }
    }
}
