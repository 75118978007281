
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    name: 'Checkbox',
    components: {
        Icon
    },
    model: {
        event: 'change',
        prop: 'checked'
    },
    props: {
        checked: {
            type: Boolean,
            required: true
        },
        size: {
            type: String,
            default: 'sm',
            validator: val => ['sm', 'md'].includes(val)
        },
        isDash: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
